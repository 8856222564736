import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '@org/ui/theme';
import PropTypes from 'prop-types';
import 'firebase/auth';
import 'firebase/firestore';
import { LoggedInProvider, DeclarationSignedProvider } from '@shared/context';
import '@shared/css/index.css';

export const wrapRootElement = ({ element }) => {
  return (
    <DeclarationSignedProvider>
      <LoggedInProvider>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </LoggedInProvider>
    </DeclarationSignedProvider>
  );
};

wrapRootElement.propTypes = {
  element: PropTypes.object,
};
