// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-templates-components-page-js": () => import("./../../../src/templates/components-page.js" /* webpackChunkName: "component---src-templates-components-page-js" */),
  "component---src-templates-page-blog-js": () => import("./../../../src/templates/page-blog.js" /* webpackChunkName: "component---src-templates-page-blog-js" */),
  "component---src-templates-page-custom-js": () => import("./../../../src/templates/page-custom.js" /* webpackChunkName: "component---src-templates-page-custom-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

