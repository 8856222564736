import { useState } from 'react';

export function useLocalstorage(initialKey) {
  const [localValue, setLocalValue] = useState(
    (typeof window !== 'undefined' && localStorage.getItem(initialKey)) ||
      (typeof window !== 'undefined' && sessionStorage.getItem(initialKey)),
  );

  function setLoacalstorage(val, session = false) {
    if (session) {
      typeof window !== 'undefined' && sessionStorage.setItem(initialKey, val);
      typeof window !== 'undefined' && localStorage.removeItem(initialKey);
      setLocalValue(typeof window !== 'undefined' && sessionStorage.getItem(initialKey));
    } else {
      typeof window !== 'undefined' && localStorage.setItem(initialKey, val);
      typeof window !== 'undefined' && sessionStorage.removeItem(initialKey);
      setLocalValue(typeof window !== 'undefined' && localStorage.getItem(initialKey));
    }
  }

  return [localValue, setLoacalstorage];
}

export default useLocalstorage;
