import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  type: 'light',
  default: { main: '#fff', contrastText: '#000' },
  primary: { main: '#11a99d', contrastText: '#fff' },
  secondary: { main: '#fff', contrastText: '#000' },
  tertiary: { main: '#fff', contrastText: '#11a99d' },
};
const themeName = "Indigo Pelorous Linne's Two-toed Sloth";

export default createMuiTheme({ palette, themeName });
