import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getUser } from '@shared/utils/auth';
import useLocalStorage from '@shared/hooks/use-localstorage';

const LoggedInContext = React.createContext({
  loggedIn: false,
});

const DeclarationSignedContext = React.createContext({
  isSigned: false,
});

function LoggedInProvider({ children }) {
  const [loggedIn, setLoggedIn] = useState(getUser());

  return (
    <LoggedInContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
      }}
    >
      {children}
    </LoggedInContext.Provider>
  );
}

function DeclarationSignedProvider({ children }) {
  const [isSigned, setIsSigned] = useLocalStorage('acceptedDeclaration');
  return (
    <DeclarationSignedContext.Provider
      value={{
        isSigned: isSigned === true || isSigned === 'true',
        setIsSigned,
      }}
    >
      {children}
    </DeclarationSignedContext.Provider>
  );
}

LoggedInProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

DeclarationSignedProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { LoggedInProvider, LoggedInContext, DeclarationSignedProvider, DeclarationSignedContext };
