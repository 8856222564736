import firebase from 'gatsby-plugin-firebase';

export const isBrowser = () => typeof window !== 'undefined';

export const onLogInChange = (fn) => {
  return fn();
};

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user')
    ? JSON.parse(window.localStorage.getItem('user'))
    : null;

export const setUser = (user, cb) => {
  isBrowser() && window.localStorage.setItem('user', JSON.stringify(user));
  return isBrowser() && cb && cb();
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user?.email;
};

export const logout = () => {
  return new Promise((resolve) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        resolve();
      });
  });
};
